/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
section#topbar {
  height: 0;
  padding: 0;
}

#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 16px 0;
  background: rgba(40, 58, 90, 0.9);
}
#header .logo {
  font-size: 22px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
#header .logo a {
  color: #fff;
}
#header .logo img {
  max-height: 40px;
}


@media (max-width: 992px) {
  #header .logo {
    font-size: 20px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar a, .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
}
.navbar a i, .navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
  color: #47b2e4;
}
.navbar .getstarted, .navbar .getstarted:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  border: 2px solid #47b2e4;
  font-weight: 600;
}
.navbar .getstarted:hover, .navbar .getstarted:focus:hover {
  color: #fff;
  background: #31a9e1;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}
.navbar .dropdown ul li {
  min-width: 200px;
}
.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  color: #0c3c53;
}
.navbar .dropdown ul a i {
  font-size: 12px;
}
.navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
  color: #47b2e4;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 22px;
  cursor: pointer;
  display: none;
  line-height: 0;
  opacity: 0.8;
}
.mobile-nav-toggle.actived {
  color: #fff;
  font-size: 18px;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }

  .navbar > ul > li {
    width: calc(100% - 12px);
  }


  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(20, 38, 70, 0.9);
    transition: 0.3s;
    z-index: 9991;
  }
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 30px;
    right: 25px;
  }
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 16px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
    border-radius: 8px;
  }
  .navbar-mobile a, .navbar-mobile a:focus {
    height: unset;
    padding: 10px 20px;
    font-size: 16px;
    color: #222222;
    border-radius: 2px;
  }
  .navbar-mobile > ul > li {
    padding: 0;
  }
  
  .navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
    color: #47b2e4;
  }

  .navbar-mobile a:before {
    content: "";
    position: absolute;
    width: 3px !important;
    height: calc(100% - 12px) !important;
    left: unset !important;
    top: 6px;
    right: 6px;
    background-color: #47b2e4;
    visibility: hidden !important;
    transition: unset !important;
  }

  .navbar-mobile a:hover:before, .navbar-mobile a:focus:before, .navbar-mobile a.active:before {
    visibility: visible !important;
  }


  .navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
    margin: 15px;
  }
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  .navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
    color: #106eea;
  }
  .navbar-mobile .dropdown > .dropdown-active {
    display: block;
  }
}

/* LAZY LOAD PLACEHOLDER */
.lazyload-placeholder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    animation: lazyload_placeholder_animation 1.6s infinite;
}
  
@keyframes lazyload_placeholder_animation {
    0% {
      background: #eee;
    }
    50% {
      background-color: #bdbdbd;
    }
    100% {
      background: #eee;
    }
};
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#home {
  width: 100%;
  height: 80vh;
  background: #37517e;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

#home:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 98;
}

#home .container {
  padding-top: 72px;
  z-index: 99;
}
#home h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}
#home h2 {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 12px;
  font-size: 24px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
}
#home .btn-get-started {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px 11px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px 0 0 0;
  color: #fff;
  background: #47b2e4;
}
#home .btn-get-started:hover {
  background: #209dd8;
}
#home .btn-watch-video {
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: 0.5s;
  margin: 10px 0 0 25px;
  color: #fff;
  line-height: 1;
}
#home .btn-watch-video i {
  line-height: 0;
  color: #fff;
  font-size: 32px;
  transition: 0.3s;
  margin-right: 8px;
}
#home .btn-watch-video:hover i {
  color: #47b2e4;
}
#home .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@media (max-width: 991px) {
  #home {
    height: 100vh;
    text-align: center;
  }
  #home .animated {
    -webkit-animation: none;
    animation: none;
  }
  #home .hero-img {
    text-align: center;
  }
  #home .hero-img img {
    width: 50%;
  }
}
@media (max-width: 768px) {
  #home h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #home h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  #home .hero-img img {
    width: 70%;
  }
}
@media (max-width: 575px) {
  #home .hero-img img {
    width: 80%;
  }
  #home .btn-get-started {
    font-size: 16px;
    padding: 10px 24px 11px 24px;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
/*--------------------------------------------------------------
# Bedrooms
--------------------------------------------------------------*/
.bedrooms .member {
  position: relative;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 5px;
  background: #fff;
  transition: 0.5s;
  margin: 12px 0px;
  cursor: pointer;
}
.bedrooms .member .pic {
  overflow: hidden;
  width: 180px;
  border-radius: 4px;
  position: relative;
}
.bedrooms .member .pic img {
  transition: ease-in-out 0.3s;
}
.bedrooms .member:hover {
  transform: translateY(-10px);
}
.bedrooms .member .member-info {
  padding-left: 30px;
}
.bedrooms .member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #37517e;
}
.bedrooms .member span {
  display: block;
  font-size: 14px;
  padding-bottom: 10px;
  position: relative;
  /* font-weight: 500; */
}
.bedrooms .member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #cbd6e9;
  bottom: 0;
  left: 0;
}
.bedrooms .member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}
.bedrooms .member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.bedrooms .member .social a {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  background: #eff2f8;
}
.bedrooms .member .social a i {
  color: #37517e;
  font-size: 16px;
  margin: 0 2px;
}
.bedrooms .member .social a:hover {
  background: #47b2e4;
}
.bedrooms .member .social a:hover i {
  color: #fff;
}
.bedrooms .member .social a + a {
  margin-left: 8px;
}